import React from "react"
import styled from '@emotion/styled';
import tw from 'tailwind.macro';


 const Wrapper = tw.div`
  mx-auto py-16 px-8 md:px-16
 `;

 const Subheading = styled.span`
  ${tw`
    block my-0 font-display font-light text-3xl
  `}
  letter-spacing: .05rem;
  margin-bottom: -1rem;
 `;

 const StyledH2 = styled.h2`
  border-bottom: 6px solid;
  ${tw`
      font-display font-black text-4xl border-lightBlue max-w-xs relative mt-0`
    }
  &::after{
    position:absolute;
    content: '';
    width: 100%;
    height:3px;
    background-color: #1e1f57;
    /* border-bottom: 8px solid #00adef; */
    bottom:0;
    left: 0;
  }
 `;

 const ContentWrapper = styled.article`
  ${tw`py-8`}
  &>p, &>img {
    margin-bottom: 1.5rem;
  }
  &>p>a {
    ${tw`
      px-5 py-3 leading-none border rounded text-darkBlue border-darkBlue hover:border-transparent hover:text-white hover:bg-darkBlue mt-4 lg:mt-0
    `}
  }
 `;

const TextColumn = ({text, subheading, heading, multicolumn}) => (
  <Wrapper className={"container "}>
    <Subheading>{subheading}</Subheading>
    <StyledH2>{heading}</StyledH2>
    <ContentWrapper className={multicolumn ? "lg:columns-2" : ""} dangerouslySetInnerHTML={{__html: text}}>
      
    </ContentWrapper>
  </Wrapper>
);

export default TextColumn
