import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import TextColumns from '../components/TextColumns'
import SEO from "../components/seo"

const GetInvolved = () => {
  const data = useStaticQuery(graphql`
  query {
    form: markdownRemark(frontmatter: {title: {eq: "Success"}}) {
      html
      frontmatter {
        heading
      }
    }
  }
`)

  return (
    <Layout>
      <SEO title="Success" noindex={true} />
      <TextColumns text={data.form.html} heading={data.form.frontmatter.heading} multicolumn={false} />
    </Layout>
  )
}

export default GetInvolved

